import ReactGA from 'react-ga';
import {Cookies} from 'react-cookie'

const cookies = new Cookies();

export function gaPageView() {
    let useGa = !!(cookies.get('google-analytics-gdpr') || cookies.get('google-analytics-gdpr') === 'undefined');
    if (useGa)
    {
        ReactGA.pageview(window.location.pathname + window.location.search + window.location.hash);
    }
}