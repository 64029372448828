/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/less/all.less";

import ReactGA from 'react-ga';
import {Cookies} from 'react-cookie'
import {gaPageView} from './src/components/helpers'

const cookies = new Cookies();


export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
        await import(`intersection-observer`);
    }

    // Use Google Analytics if cookie consent

    let useGa = !!(cookies.get('google-analytics-gdpr') || cookies.get('google-analytics-gdpr') === 'undefined');
    if (useGa)
    {
        ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);
        gaPageView();
    }
}